import React, { Component, } from 'react'
import { Row, Col } from 'react-bootstrap'
import StyleEmployee from "../styles/employee.module.scss"

// Passing props
// title=""
// content=""
// imgSrc=""
// imgAlt"
// orderImageLast="" // true if we want to set the image in the 2 col
// padding="" //add padding modification if needed
// BtnText==""
// children to pass additional html 
// trial="" // add top content for free trial message
export default class HalfContentImage extends Component {
    render() {
        return (
            <div className={`${StyleEmployee.employeeAdvocacySpace} container`} style={{ padding: this.props.padding, margin: this.props.margin }}>
                <Row className={StyleEmployee.employeeAdvocacy} data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease-out-back">
                    <Col md={6} lg={6} sm={12} xs={12} className={`d-flex align-self-center ${this.props.orderImageLast ? 'order-2' : ''}`}>
                        <img src={this.props.imgSrc} className={`${this.props.classImage} img-fluid`} alt={this.props.imgAlt} />
                    </Col>
                    <Col md={6} lg={6} sm={12} xs={12}>
                        <div className={this.props.trial ? 'mb-5' : 'd-none'}>
                            {this.props.trial}
                        </div>
                        <h2>{this.props.title}</h2>
                        {this.props.content}
                        {this.props.children}
                    </Col>
                </Row>
            </div>
        )
    }
}
