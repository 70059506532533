import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import StyleClients from "../styles/clients.module.scss"

export default class CommentComponent extends Component {
    render() {
        return (
            <div className="container-fluid" style={{ padding: this.props.padding, margin: this.props.margin }} data-sal="slide-left" data-sal-delay="100" data-sal-easing="ease-out-back">
                <Row className="justify-content-md-center">
                    <Col lg={8} className={`${StyleClients.box}`} style={{ marginTop: 0, minHeight: "inherit" }}>
                        <div className="row">
                            <Col lg={12}>
                                <h2 style={{ textAlign: 'left' }}>
                                    {this.props.commentTitle}
                                </h2>
                            </Col>
                            <Col lg={4} xl={4} md={4} sm={12}>
                                <img src={this.props.imgSrc} alt={this.props.imgAlt} className={`${StyleClients.image} img-fluid m-auto d-block`} />
                            </Col>
                            <Col lg={8} xl={8} md={8} sm={12}>
                                <div className={StyleClients.comment}>
                                    {this.props.comment}
                                    <div className={StyleClients.comment}>
                                        {this.props.children}
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
