import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import "../styles/global.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BtnStyle from '../styles/button.module.scss';
import StyleEmployee from '../styles/employee.module.scss';
import CommentComponent from "../components/comment"
import HalfContentImage from "../components/halfContentImage"

const EmployeeAdvocacy = () => {

    const callRegisterForm = (e) => {
        e.preventDefault();
        window.__stent.toggleModal(true, {
            planId: "prod_JJ3g0q0BBQRNyq",
            env: "production",
            lang: 'en',
            currency: "usd",
            showRegisterCardStep: "no",
        });;
    }


    const Data = useStaticQuery(graphql`
    query {        
        # Header
      contentfulEaHeader{
        eaHeaderTitle
        eaHeaderCta
        eaHeaderSubtitle
        eaHeaderContent {
            json
        }        
      }
    #   what is EA
        contentfulEaWhatIs{
            eaWhatIsTitle
            eaWhatIsContent{
                json
            }
             eaWhatIsImage {
                file {
                    url
                    fileName
                }
            }
        }
        # firstblock
        contentfulEaFirstBlock{
            eaFirstBlockTitle
            eaFirstBlockContent{
                json
            }
            eaFirstBlockImage{
                file{
                    url
                    fileName
                }
            }
        }
         # secondBlock
        contentfulEaSecondBlock{
            eaSecondBlockTitle
            eaSecondBlockBtn
            # eaSecondBlockBtnLink
            eaSecondBlockContent{
                json
            }
            eaSecondBlockImage{
                file{
                    url
                    fileName
                }
            }
        }
         # thirdBlock
        contentfulEaThirdBlock{
            eaThirdBlockTitle
            eaThirdBlockContent{
                json
            }
            eaThirdBlockImage{
                file{
                    url
                    fileName
                }
            }
        }
         # fourth block
        contentfulEaFourthBlock{
            eaFourthBlockTitle
            eaFourthBlockContent{
                json
            }
            eaFourthBlockImage{
                file{
                    url
                    fileName
                }
            }
        }
        # First block comment
        contentfulEaFirstComment{
            eaFirstCommentTitle
            eaFirstCommentContent{
                json
            }
            eaFirstCommentImage{
                file{
                    url
                    fileName
                }
            }
        }
        # Second block comment
        contentfulEaSecondComment{
            eaSecondCommentTitle
            eaSecondCommentContent{
                json
            }
            eaSecondCommentImage{
                file{
                    url
                    fileName
                }
            }           
        }
        contentfulEaFeatures{
            eaFeaturesTitle
            eaFeaturesCta
            eaFreeBlockText{
                json
            }
            eaFeatureContent{
                json
            }
            eaFeatureImage{
                file{
                    url
                    fileName
                }   
            }         
        }
    }
  `)

    const headerData = Data.contentfulEaHeader;
    const whatIs = Data.contentfulEaWhatIs;
    const firstBlock = Data.contentfulEaFirstBlock;
    const secondBlock = Data.contentfulEaSecondBlock;
    const thirdBlock = Data.contentfulEaThirdBlock;
    const fourthBlock = Data.contentfulEaFourthBlock;
    const firstComment = Data.contentfulEaFirstComment;
    const secondComment = Data.contentfulEaSecondComment;
    const features = Data.contentfulEaFeatures;

    return (
        <Layout>
            <SEO title="Share Content With Stent Ai" />
            <div className={StyleEmployee.employeeAdvocacySpace}>
                <div className="backImage"></div>
                <div className="container">
                    <Row>
                        <Col lg={7} className={StyleEmployee.customClass}>
                            <h1>{headerData.eaHeaderTitle}</h1>
                            <h5>{headerData.eaHeaderSubtitle}</h5>
                            {documentToReactComponents(headerData.eaHeaderContent.json)}
                            <a
                                onClick={callRegisterForm}
                                className={`${BtnStyle.btnStent} mt-4 mb-4`}
                            >
                                {headerData.eaHeaderCta}
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* What is employee advocacy? */}
            <HalfContentImage
                title={whatIs.eaWhatIsTitle}
                content={documentToReactComponents(whatIs.eaWhatIsContent.json)}
                imgSrc={whatIs.eaWhatIsImage.file.url}
                imgAlt={whatIs.eaWhatIsImage.fileName}
                orderImageLast=""
                margin="160px auto 120px auto"
                classImage={StyleEmployee.halfImage}
            />

            {/* Your collaborators */}
            <HalfContentImage
                title={firstBlock.eaFirstBlockTitle}
                content={documentToReactComponents(firstBlock.eaFirstBlockContent.json)}
                imgSrc={firstBlock.eaFirstBlockImage.file.url}
                imgAlt={firstBlock.eaFirstBlockImage.fileName}
                orderImageLast="true"
                classImage={StyleEmployee.halfImage}
                margin="0 auto 120px auto"
            />

            {/* first comment */}
            <CommentComponent
                commentTitle={firstComment.eaFirstCommentTitle}
                comment={documentToReactComponents(firstComment.eaFirstCommentContent.json)}
                imgSrc={firstComment.eaFirstCommentImage.file.url}
                imgAlt={firstComment.eaFirstCommentImage.file.fileName}
            />

            {/* Our cutting-edge technology */}
            <HalfContentImage
                title={secondBlock.eaSecondBlockTitle}
                content={documentToReactComponents(secondBlock.eaSecondBlockContent.json)}
                imgSrc={secondBlock.eaSecondBlockImage.file.url}
                imgAlt={secondBlock.eaSecondBlockImage.file.fileName}
                orderImageLast=""
                classImage={StyleEmployee.halfImage}
                margin="120px auto">
            </HalfContentImage>

            {/* A unique multi-functional interface */}
            <HalfContentImage
                title={thirdBlock.eaThirdBlockTitle}
                content={documentToReactComponents(thirdBlock.eaThirdBlockContent.json)}
                imgSrc={thirdBlock.eaThirdBlockImage.file.url}
                imgAlt={thirdBlock.eaThirdBlockImage.file.fileName}
                orderImageLast="true"
                classImage={StyleEmployee.halfImage}
                margin="120px auto">
            </HalfContentImage>

            {/* 4. Reward engagement */}
            <HalfContentImage
                title={fourthBlock.eaFourthBlockTitle}
                content={documentToReactComponents(fourthBlock.eaFourthBlockContent.json)}
                imgSrc={fourthBlock.eaFourthBlockImage.file.url}
                imgAlt={fourthBlock.eaFourthBlockImage.file.fileName}
                orderImageLast=""
                classImage={StyleEmployee.halfImage}>
            </HalfContentImage>

            {/* Second comment */}
            <CommentComponent
                commentTitle={secondComment.eaSecondCommentTitle}
                comment={documentToReactComponents(secondComment.eaSecondCommentContent.json)}
                imgSrc={secondComment.eaSecondCommentImage.file.url}
                imgAlt={secondComment.eaSecondCommentImage.file.fileName}
                margin="160px auto"
            />

            {/* free features */}
            <HalfContentImage
                title={features.eaFeaturesTitle}
                content={documentToReactComponents(features.eaFeatureContent.json)}
                imgSrc={features.eaFeatureImage.file.url}
                imgAlt={features.eaFeatureImage.file.fileName}
                orderImageLast="true"
                classImage={StyleEmployee.halfImage}
                trial={documentToReactComponents(features.eaFreeBlockText.json)}>
                <a
                    onClick={callRegisterForm}
                    className={`${BtnStyle.btnStent} mt-4 mb-4`}
                >
                    {features.eaFeaturesCta}
                </a>
            </HalfContentImage>
        </Layout>
    )
}

export default EmployeeAdvocacy